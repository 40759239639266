@import '../node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../node_modules//bulma/sass/utilities/functions';

@font-face {
  font-family: 'fourmasters-vandenkeere';
  src: url('./FourMasters-VandenKeere.woff2') format('woff2');
}

$highlight: rgb(0, 249, 0);
$dark-grey: rgb(74, 74, 74);
$main-color: rgb(20, 85, 255);

$primary: $main-color;

@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
@import '../node_modules/bulma/sass/layout/footer.sass';
@import '../node_modules/bulma/sass/grid/columns.sass';
@import '../node_modules/bulma/sass/components/level.sass';
@import '../node_modules//bulma/sass/helpers/typography.sass';

body {
  font-family: 'fourmasters-vandenkeere', sans-serif;
  color: $main-color;
  margin: 0;
}

// Intro

.hero-body {
  padding: 1rem 1.5rem;
}

.centered {
  margin: 0 auto;
}

.invert {
  background-color: $main-color;
  color: white;
}

.symbol {
  white-space: pre;
  background-color: $main-color;
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    background-color: $main-color;
  }
  49% {
    background-color: $main-color;
  }
  60% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: $main-color;
  }
}

.intro-text {
  font-size: 2rem;
}

// Contact

.button {
  font-family: 'fourmasters-vandenkeere', sans-serif;
  font-size: 1rem;
  border-radius: 14px;
  border: 1px solid $main-color;
  padding: calc(0.5em - 1px) 2.5rem;
}

.contact {
  margin: 3rem 0;

  .button {
    font-family: 'Helvetica', sans-serif;
    box-shadow: inset 8px 20px 22px white, inset -10px -6px 6px $main-color;
  }
}

a {
  figure {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.contact-link {
  padding-left: 10px;
}

.columns.is-mobile {
  .column {
    margin: 0.5rem 1rem;
  }
}

.is-underlined {
  margin-bottom: 0.5rem;
  text-decoration: underline;
}

// Skills/Tools

.label {
  font-weight: 700;
  color: $dark-grey;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
}

figure {
  text-align: center;
}

svg {
  fill: $dark-grey;
  width: 2rem;
  height: 2rem;
}

.column {
  figure {
    height: 38px;
  }
}

.skill-highlight {
  .label {
    color: $main-color;
  }
  figure {
    svg {
      fill: $main-color;
    }
  }
}

// Projects

@media only screen and (max-width: 600px) {
  .project-block {
    padding: 3rem 1.5rem;
  }
}

.column {
  img {
    width: 100%;
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description {
  p {
    margin: 0 auto;
    font-size: 0.9rem;
  }
  div {
    span {
      font-size: 0.9rem;
    }
  }
}

@media only screen and (min-width: 375px) {
  .description {
    p {
      font-size: 1.1rem;
    }
    div {
      span {
        font-size: 1.1rem;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .description > p {
    padding: 0 3rem;
  }
}
.slide-control {
  margin: 0 0 2rem 0;

  button {
    cursor: pointer;
    background-color: white;
    border: none;
    font-weight: 300;
    font-family: 'fourmasters-vandenkeere', sans-serif;
    color: $main-color;
    padding: 10px 14px;
    &:focus {
      outline: 0;
    }
  }

  @media only screen and (min-width: 400px) {
    button {
      margin: 0 0.5rem;
    }
  }

  .project-number {
    transition: color 0.3s ease-in-out;
  }

  .project-number:not(.current):hover {
    color: $dark-grey;
  }

  .current {
    color: $main-color;
    font-weight: 700;
    // background-color: $main-color;
    text-align: center;
    border: 1px solid $main-color;
    border-radius: 100px;
  }

  .active {
    color: $main-color;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content {
  text-align: center;
}

.deployment-links {
  margin: 1rem 1rem 0rem;
  display: flex;
  justify-content: space-around;
  a {
    svg {
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      svg {
        fill: $main-color;
      }
      .label {
        color: $main-color;
      }
    }
    text-align: center;
  }
}

// About

// Experience

.experience {
  margin-bottom: 1rem;
  div {
    font-weight: 600;
  }
}

// Interests

.interest {
  margin-top: 1rem;
}

// Contact

.contact-links {
  a {
    margin: 0 auto;
    max-width: 320px;
  }
}

.level-item {
  color: $main-color;
  svg {
    fill: $main-color;
  }

  &:hover {
    color: $dark-grey;
    svg {
      fill: $dark-grey;
    }
  }
}

// Footer

.buttons {
  .button {
    box-shadow: 12px 12px 24px 0px #082266, -12px -8px 20px 4px #209eff;
  }
}

.footer {
  background-color: $main-color;
  padding: 2rem 0rem;
  color: white;
  font-family: 'Helvetica', sans-serif;
  font-size: 0.8rem;
  button {
    font-family: 'Helvetica', sans-serif;
  }
  .footer-text {
    padding-top: 0.8rem;
  }
  span {
    font-weight: 700;
    color: white;
  }

  a {
    color: white;
  }
  svg {
    fill: white;
  }
}
